import React, { useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useBeforeunload } from 'react-beforeunload';
import { gql, useMutation } from '@apollo/client';
import styled from 'styled-components';
import useForm from 'ls-common-client/src/hooks/useForm';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Avatar from 'ls-common-client/src/components/Avatar';
import Icon from 'ls-common-client/src/components/Icon';
import FancyInput from 'ls-common-client/src/components/FancyInput';
import FileUpload from 'ls-common-client/src/components/FileUpload';
import AutoSuggest from 'ls-common-client/src/components/AutoSuggest';
import Dialog from 'ls-common-client/src/components/Dialog';
import ImageEditor from 'ls-common-client/src/components/ImageEditor';
import Button from 'ls-common-client/src/components/Button';
import EmptyButton from 'ls-common-client/src/components/EmptyButton';
import Validator from 'ls-common-client/src/components/Validator';
import Popup from 'ls-common-client/src/components/Popup';
import Menu from 'ls-common-client/src/components/Menu';
import ClickOutside from 'ls-common-client/src/components/ClickOutside';
import { fromFile, toFile } from 'ls-common-client/src/image';
import { toBase64 } from 'ls-common-client/src/file';
import { imageResizer, uploader } from '../../../services';
import { H1 } from '../../common';
import { AppContext } from '../../../context';
import { AvatarDefault } from '../../../svgs';
import config from '../../../config';

const { api } = config;

const updateUserMutation = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      user {
        avatar
        displayName
        suburbId
      }
    }
  }
`;

const AvatarButton = styled(EmptyButton)`
  cursor: pointer;
  position: relative;
  max-width: 300px;
  height: auto;
  width: 100%;
  padding-top: 100%;
  display: block;
`;

const StyledForm = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const getLocationString = location => {
  const { name, suburbName, state, postcode } = location || {};
  if (!name && !suburbName) return '';
  return `${name || suburbName} ${state}${postcode ? ` ${postcode}` : ''}`;
};

const Profile = ({ setShowSuccess }) => {
  const {
    media: { mobile },
    user: { user, refetch },
    handleError,
  } = useContext(AppContext.Context);

  const { avatar, suburb, displayName, id, suburbId } = user;

  const [isOver, setIsOver] = useState(false);
  const [base64, setBase64] = useState();
  const [base64Edited, setBase64Edited] = useState();
  const [showImageCropper, setShowImageCropper] = useState(false);
  const [results, setResults] = useState(null);
  const [showEditMenu, setShowEditMenu] = useState(false);
  const uploadRef = useRef();
  const displayNameRef = useRef();
  const locationRef = useRef();

  const [mutateUser] = useMutation(updateUserMutation, {
    onError: handleError,
  });

  const formatAvatar = async val => {
    if (val === avatar || !val) return null;
    const file = await toFile(val);
    return file;
  };

  const uploadAvatar = file => {
    if (!file) return null;

    const { size, type } = file;

    if (size > config.maxUploadSize) {
      throw new Error('Invalid file size');
    }
    if (!type.includes('image/')) {
      throw new Error('Invalid file type');
    }
    return uploader.upload(file);
  };

  const {
    values,
    setValue,
    loading,
    changed,
    onSubmit,
    valid,
    validation: { valueMissing },
  } = useForm({
    defaults: {
      displayName: displayName || '',
      location: getLocationString(suburb),
      suburbId,
    },
    onSubmit: async vals => {
      const formatted = await formatAvatar(base64Edited);
      const uploaded = await uploadAvatar(formatted);

      const hasChange = name =>
        user[name] !== vals[name] && vals[name] ? { [name]: vals[name] } : null;

      await mutateUser({
        variables: {
          input: {
            id,
            ...(uploaded ? { avatar: uploaded } : {}),
            ...(hasChange('displayName') || {}),
            ...(hasChange('suburbId') || {}),
          },
        },
      });

      setShowSuccess(true);

      await refetch();

      if (uploaded) {
        setBase64(null);
        setBase64Edited(null);
      }
    },
  });

  useBeforeunload(e => {
    return changed() ? e.preventDefault() : null;
  });

  const onSelected = async file => {
    const img = await fromFile(file[0]);
    setBase64(img.src);
    setShowImageCropper(true);
    setIsOver(false);
  };

  const onDragOver = e => {
    e.stopPropagation();
    e.preventDefault();
    setIsOver(true);
  };

  const onDragLeave = () => {
    setIsOver(false);
  };

  const onImageEdited = img => {
    setBase64Edited(img);
    setShowImageCropper(false);
  };

  const onDialogClose = () => {
    setBase64(base64Edited ? base64 : null);
    setShowImageCropper(false);
  };

  const onLocationClick = loc => {
    setValue({ name: 'location', value: loc.text });
    setValue({ name: 'suburbId', value: `${loc.id}` });
    setResults(null);
  };

  const onLocationClear = () => {
    setValue({
      value: '',
      name: 'location',
      validity: { valueMissing: !!getLocationString(suburb) },
    });
    setValue({ name: 'suburbId', value: null });
    setResults(null);
    locationRef.current.focus();
  };

  const fetchLocations = async value => {
    const res = await fetch(
      `${api}/v1/search/suggest/suburbs?keyword=${value}&online=true`
    );

    const { data } = await res.json();

    setResults(
      data.map(loc => {
        const text = getLocationString(loc);
        return {
          ...loc,
          text,
        };
      })
    );
  };

  const onLocationChange = ({ target: { value, name } }) => {
    setValue({ name, value, validity: { valueMissing: true } });
    setValue({ name: 'suburbId', value: null });
    if (value) {
      fetchLocations(value);
    } else {
      setResults(null);
    }
  };

  const onFileUpload = () => {
    setShowEditMenu(false);
    uploadRef.current.click();
  };

  const onFileDrop = e => {
    e.preventDefault();
    onSelected(e.dataTransfer.files);
  };

  const onEditImageClick = async () => {
    setShowEditMenu(false);
    if (!base64) {
      const res = await fetch(avatar);
      const blob = await res.blob();
      const image = await toBase64(blob);
      setBase64(image);
    }
    setShowImageCropper(true);
  };

  const avatarSrc =
    base64Edited || imageResizer.resize(avatar, { width: 600, height: 600 });

  return (
    <>
      <StyledForm onSubmit={onSubmit} autoComplete="off">
        <H1 marginBottom="30px">Edit Your Profile</H1>
        <ClickOutside
          maxWidth="300px"
          marginBottom="20px"
          onClickOutside={() => setShowEditMenu(false)}
        >
          <AvatarButton
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onFileDrop}
            onClick={() => {
              if (!showEditMenu) {
                setShowEditMenu(true);
              }
            }}
          >
            <Avatar
              width="100%"
              height="100%"
              position="absolute"
              left="0"
              top="0"
              boxShadow="0 0 7px 0 rgba(0,0,0,0.4)"
              src={avatarSrc}
              border={isOver ? '3px dashed' : ''}
              borderColor="border700"
              objectFit="cover"
            >
              {avatarSrc ? null : <AvatarDefault />}
            </Avatar>
            <Container
              backgroundColor="rgba(0, 0, 0, 0.4)"
              borderRadius="4px"
              position="absolute"
              left="50%"
              top="50%"
              padding="0 15px 0 8px"
              height="43px"
              transform="translate(-50%,-50%)"
              display="flex"
              alignItems="center"
            >
              <Icon
                iconColor="light"
                iconSize="large"
                marginBottom="-4px"
                marginRight="5px"
                className="ls-icon icon-categoryphotography"
              />
              <Text color="text100" fontWeight="600" fontSize="18px">
                Change
              </Text>
            </Container>
          </AvatarButton>
          <Popup
            top="170px"
            width="100%"
            maxWidth="213px"
            minWidth="auto"
            show={showEditMenu}
            anchor="top"
          >
            <Menu
              data={[
                ...(avatarSrc
                  ? [
                      {
                        text: 'Edit Image',
                        onClick: onEditImageClick,
                        icon: (
                          <Icon
                            fontSize="28px"
                            className="ls-icon icon-generaledit"
                            width="38px"
                            textAlign="center"
                          />
                        ),
                      },
                      {
                        splitter: true,
                      },
                    ]
                  : []),
                {
                  text: 'Upload new Image',
                  onClick: onFileUpload,
                  icon: (
                    <Icon
                      fontSize="28px"
                      className="ls-icon icon-generalshare"
                      width="38px"
                      textAlign="center"
                    />
                  ),
                },
              ]}
            />
          </Popup>
        </ClickOutside>
        <FileUpload display="none" ref={uploadRef} onSelected={onSelected} />
        <Container marginBottom="25px">
          <FancyInput
            name="displayName"
            fontSize="22px"
            fontWeight="600"
            label="Display Name"
            value={values.displayName}
            onClear={() => {
              setValue({
                value: '',
                name: 'displayName',
                validity: { valueMissing: !!displayName },
              });
              displayNameRef.current.focus();
            }}
            onChange={setValue}
            ref={displayNameRef}
          />
          {valueMissing('displayName') && (
            <Validator>Let us know what we can call you.</Validator>
          )}
        </Container>
        <Container marginBottom="20px">
          <FancyInput
            name="location"
            type="location"
            onChange={onLocationChange}
            onClear={onLocationClear}
            label="Location"
            icon={
              <Icon
                iconSize="30px"
                width="34px"
                marginBottom="4px"
                className="ls-icon icon-categorynearme"
              />
            }
            value={values.location}
            ref={locationRef}
          />
          <Container position="relative" marginTop="10px">
            <AutoSuggest data={results} onSelect={onLocationClick} />
          </Container>
          {valueMissing('location') && (
            <Validator>Oops! Looks like nothing is selected.</Validator>
          )}
        </Container>
        <Container display="flex" justifyContent="center" marginTop="auto">
          <Button
            disabled={(!changed() && !base64Edited) || !valid()}
            maxWidth="215px"
            rounded
            primary
            type="submit"
            loading={loading}
          >
            Save Changes
          </Button>
        </Container>
      </StyledForm>
      <Dialog
        show={showImageCropper}
        onClose={onDialogClose}
        header="Edit Avatar"
        mode={!mobile ? 'desktop' : 'mobile'}
      >
        <ImageEditor
          canvasProps={{
            minWidth: '100%',
          }}
          padding="16px"
          src={base64}
          onChanged={onImageEdited}
          fixed
          rounded
        />
      </Dialog>
    </>
  );
};

Profile.propTypes = {
  setShowSuccess: PropTypes.func,
};

Profile.defaultProps = {
  setShowSuccess: () => {},
};

export default Profile;
