import React, { useContext } from 'react';
import styled from 'styled-components';
import Container from 'ls-common-client/src/components/Container';
import AppMenu from 'ls-common-client/src/components/AppMenu';
import UserMenu from 'ls-common-client/src/components/UserMenu';
import AnchorLink from 'ls-common-client/src/components/AnchorLink';
import LsLogo from 'ls-common-client/src/components/Logo';
import { auth, imageResizer } from '../../services';
import { AppContext } from '../../context';
import { Logo } from '../../svgs';

const Gradient = styled.div`
  background: linear-gradient(
    180deg,
    rgba(152, 176, 253, 1) 0%,
    rgba(45, 96, 251, 1) 100%
  );
  position: fixed;
  width: 100%;
  z-index: 2;
`;

const Header = () => {
  const {
    user: { user },
    media: { mobile },
  } = useContext(AppContext.Context);

  const { isStaff, isPersoniv } = user || {};

  return (
    <Gradient margin="auto">
      <Container
        maxWidth="1320px"
        height="70px"
        margin="auto"
        alignItems="center"
        padding="0 20px"
        display="flex"
      >
        <AnchorLink to="/">
          {mobile ? <LsLogo type="reverseSmall" width="47px" /> : <Logo />}
        </AnchorLink>
        <Container marginLeft="auto" display="flex" alignItems="center">
          <AppMenu
            isStaff={isStaff}
            isPersoniv={isPersoniv}
            width="34px"
            height="34px"
          />
          <UserMenu
            isUserManagement
            marginLeft="13px"
            auth={auth}
            user={user}
            imageResizer={imageResizer}
            width="34px"
            height="34px"
          />
        </Container>
      </Container>
    </Gradient>
  );
};

export default Header;
