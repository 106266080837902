import React from 'react';

const PadLock = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="53"
    viewBox="0 0 36 53"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#40A499"
            d="M34.593 25.312h-2.531V14.343C32.062 6.412 25.649 0 17.718 0 9.788 0 3.375 6.412 3.375 14.343v10.969H.844c-.506 0-.844.337-.844.844v25.312c0 .506.338.844.844.844h33.75c.506 0 .843-.338.843-.844V26.156c0-.507-.337-.844-.843-.844zM5.062 14.343c0-7.003 5.653-12.656 12.656-12.656 7.003 0 12.657 5.653 12.657 12.656v10.969h-1.688V14.343c0-6.075-4.894-10.968-10.968-10.968-6.075 0-10.969 4.894-10.969 10.968v10.969H5.063l-.001-10.969zm21.937 10.969H8.437V14.343c0-5.146 4.134-9.281 9.281-9.281S27 9.196 27 14.343v10.969zm6.75 25.312H1.687V27h32.062v23.625z"
            transform="translate(-772 -229) translate(772 229)"
          />
          <path
            fill="#285AFF"
            d="M4.218 28.687c-.506 0-.844.337-.844.844v18.562c0 .506.338.844.844.844h27c.506 0 .844-.338.844-.844V29.531c0-.507-.338-.844-.844-.844h-27zm26.157 18.562H5.062V30.374h25.313V47.25z"
            transform="translate(-772 -229) translate(772 229)"
          />
          <path
            fill="#285AFF"
            d="M16.875 38.98v4.05c0 .507.337.844.843.844.507 0 .844-.337.844-.844v-4.05c1.013-.337 1.688-1.265 1.688-2.362 0-1.434-1.097-2.531-2.532-2.531-1.434 0-2.53 1.097-2.53 2.53 0 1.098.674 2.026 1.687 2.363zm.843-3.206c.507 0 .844.338.844.844s-.337.844-.844.844c-.506 0-.843-.338-.843-.844s.337-.844.843-.844z"
            transform="translate(-772 -229) translate(772 229)"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default PadLock;
