import React, { useState, useContext } from 'react';
import { gql, useMutation } from '@apollo/client';
import Container from 'ls-common-client/src/components/Container';
import FancyInput from 'ls-common-client/src/components/FancyInput';
import MoreButton from 'ls-common-client/src/components/MoreButton';
import Button from 'ls-common-client/src/components/Button';
import Validator from 'ls-common-client/src/components/Validator';
import useForm from 'ls-common-client/src/hooks/useForm';
import {
  Panel,
  H1,
  H2,
  Paragraph,
  BackBar,
  SuccessNotification,
} from '../common';
import { PadLock } from '../../svgs';
import { AppContext } from '../../context';

const passwordMutation = gql`
  mutation updatePassword($input: UpdatePasswordInput!) {
    updatePassword(input: $input) {
      success
    }
  }
`;

const Password = () => {
  const {
    user: { user },
    handleError,
  } = useContext(AppContext.Context);

  const [showSuccess, setShowSuccess] = useState(false);

  const [mutatePassword] = useMutation(passwordMutation, {
    onError: handleError,
  });

  const {
    values: { password, passwordConfirm },
    setValueDebounce,
    changed,
    valid,
    validation: { tooShort, dirty },
    loading,
    onSubmit,
  } = useForm({
    defaults: {
      password: '',
      passwordConfirm: '',
    },
    onSubmit: async () => {
      await mutatePassword({
        variables: {
          input: {
            userId: user.id,
            password,
          },
        },
      });
      setShowSuccess(true);
    },
  });

  return (
    <>
      <form onSubmit={onSubmit}>
        <BackBar />
        <Container display="flex" flexWrap="wrap" padding="0 10px">
          <Panel flex="1" minWidth="300px">
            <H1 marginBottom="40px">Change your password</H1>
            <Container marginBottom="20px">
              <FancyInput
                name="password"
                value={password}
                onChange={setValueDebounce}
                type="password"
                label="New Password"
                minLength="8"
                autoComplete="new-password"
                required
              />
              {tooShort('password') && (
                <Validator>
                  Password must be at least 8 characters in length.
                </Validator>
              )}
            </Container>

            <Container marginBottom="50px">
              <FancyInput
                name="passwordConfirm"
                value={passwordConfirm}
                onChange={setValueDebounce}
                type="password"
                label="Confirm New Password"
                minLength="8"
                autoComplete="new-password"
                required
              />
              {dirty('passwordConfirm') && passwordConfirm !== password && (
                <Validator>Passwords do not match.</Validator>
              )}
            </Container>
            <Button
              type="submit"
              disabled={
                !changed() ||
                !valid() ||
                (changed() && passwordConfirm !== password)
              }
              maxWidth="215px"
              rounded
              primary
              loading={loading}
            >
              Save Changes
            </Button>
          </Panel>
          <Panel
            flex="1"
            minWidth="300px"
            display="flex"
            flexDirection="column"
          >
            <Container marginBottom="20px">
              <PadLock />
            </Container>
            <H2>Your account safety is our priority.</H2>
            <Paragraph>
              We use top shelf security to protect your account and your
              details...
            </Paragraph>
            <MoreButton
              target="_blank"
              href="https://business.localsearch.com.au/legal"
              marginTop="auto"
            >
              Learn more
            </MoreButton>
          </Panel>
        </Container>
      </form>
      <SuccessNotification
        show={showSuccess}
        onClose={() => setShowSuccess(false)}
      />
    </>
  );
};

export default Password;
