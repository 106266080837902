const defaults = require('./defaults');

const { auth } = defaults;

module.exports = {
  ...defaults,
  debug: false,
  mode: 'production',
  sourceMap: 'source-map',
  hot: false,
  heapAnalyticsKey: '1090598285',
  imageResizeRoot: 'https://d2xzxktm3qm7rt.cloudfront.net',
  api: 'https://services.lsapis.com.au',
  dxpUrl: 'https://forbusiness.localsearch.com.au',
  token: {
    ...defaults.token,
    cookie: {
      domain: '.localsearch.com.au',
    },
  },
  auth: {
    ...auth,
    domain: 'auth.localsearch.com.au',
    audience: 'https://services.lsapis.com.au',
    clientID: '6JzEfKQxLlSHHoQGgqs7qev8WV7Xu2ha',
    redirectUri: 'https://account.localsearch.com.au',
  },
  deploy: {
    domain: 'account.localsearch.com.au',
    subDomain: 'account.localsearch.com.au',
    aliases: 'account.localsearch.com.au',
    certificateArn:
      'arn:aws:acm:us-east-1:708891215413:certificate/b40254e6-e360-4873-a9a6-095fcb9ef08a',
  },
};
