import AvatarDefault from './AvatarDefault';
import Bin from './Bin';
import Shield from './Shield';
import Ghost from './Ghost';
import GhostBackground from './GhostBackground';
import PadLock from './PadLock';
import Face from './Face';
import Wave from './Wave';
import Logo from './Logo';

export {
  AvatarDefault,
  Bin,
  Shield,
  Ghost,
  GhostBackground,
  PadLock,
  Face,
  Wave,
  Logo,
};
