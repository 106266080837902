import React, { useEffect, useState, useContext /* , useMemo */ } from 'react';
import { useParams /* , useNavigate, useLocation */ } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import Container from 'ls-common-client/src/components/Container';
import Text from 'ls-common-client/src/components/Text';
import Anchor from 'ls-common-client/src/components/Anchor';
import Image from 'ls-common-client/src/components/Image';
import Splash from 'ls-common-client/src/components/Splash';
import PropTypes from 'prop-types';
import { notRecognised } from '../../images';
import { AppContext } from '../../context';
import { auth } from '../../services';
import config from '../../../config';

const allInvitationMutations = {
  default: gql`
    mutation acceptInvitation($input: AcceptInvitationInput!) {
      acceptInvitation(input: $input) {
        success
      }
    }
  `,
  profile: gql`
    mutation acceptProfileInvitation($input: AcceptProfileInvitationInput!) {
      acceptProfileInvitation(input: $input) {
        success
      }
    }
  `,
  awsAccount: gql`
    mutation acceptAwsAccountInvitation(
      $input: AcceptAwsAccountInvitationInput!
    ) {
      acceptAwsAccountInvitation(input: $input) {
        success
      }
    }
  `,
};

const INVITATION_MUTATION_NAMES = {
  default: 'default',
  profile: 'profile',
  awsAccount: 'awsAccount',
};

const Invitations = ({ invitationMutationName }) => {
  // const { search } = useLocation();
  // const navigate = useNavigate();

  const { token } = useParams();
  const [error, setError] = useState(false);

  // const query = useMemo(() => new URLSearchParams(search), [search]);

  const {
    media: { mobile },
    user: { refetch },
  } = useContext(AppContext.Context);

  const invitationMutation =
    allInvitationMutations[invitationMutationName] ||
    allInvitationMutations.default;

  const [mutateInvitation] = useMutation(invitationMutation, {
    variables: {
      input: {
        token,
      },
    },
  });

  useEffect(() => {
    (async () => {
      try {
        await mutateInvitation();
        await refetch();
        await auth.refresh();

        window.location.href = config.dxpUrl || '/';
        /*
        if (query.has('redirect')) {
          window.location.href = decodeURIComponent(query.get('redirect'));
        } else {
          navigate('/');
        }
        */
      } catch (e) {
        setError(true);
      }
    })();
  }, []);

  return error ? (
    <Container
      display="flex"
      minHeight="100%"
      padding="80px 20px"
      alignItems="center"
      justifyContent="center"
    >
      <Container
        flexWrap="wrap-reverse"
        display="flex"
        alignItems="center"
        justifyContent="center"
        width="100%"
      >
        <Container minWidth="280px" maxWidth="430px">
          <Text
            marginBottom={mobile ? '20px' : '30px'}
            fontSize={mobile ? '31px' : '48px'}
            fontWeight="bold"
            lineHeight="1.2"
            display="block"
          >
            Hm, we don’t seem to recognise you…
          </Text>
          <Text
            color="text400"
            display="block"
            fontSize="18px"
            marginBottom="35px"
          >
            We’re unable to continue with your invite due to either the link
            being expired, or someone having already accepted this particular
            invite. Please get in touch with our head office on{' '}
            <Anchor href="tel:1300 360 867">1300 360 867</Anchor> or at{' '}
            <Anchor href="mailto:enquiries@localsearch.com.au">
              enquiries@localsearch.com.au
            </Anchor>{' '}
            and we’ll organise your invite for you.
          </Text>
        </Container>
        <Container
          padding="0 40px"
          justifyContent="center"
          alignItems="center"
          minWidth="315px"
          maxWidth="420px"
          marginBottom="30px"
          flex="1"
          display="flex"
        >
          <Container maxWidth="420px" width="100%" position="relative">
            <Image src={notRecognised} width="100%" />
          </Container>
        </Container>
      </Container>
    </Container>
  ) : (
    <Container
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Splash />
    </Container>
  );
};

Invitations.propTypes = {
  invitationMutationName: PropTypes.string.isRequired,
};

export default Invitations;

export { INVITATION_MUTATION_NAMES };
