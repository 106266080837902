const defaults = require('./defaults');

const { auth } = defaults;

module.exports = {
  ...defaults,
  hot: false,
  imageResizeRoot: 'https://d7dgpygyd9llm.cloudfront.net',
  api: 'https://preprod.services.lsapis.com.au',
  dxpUrl: 'https://preprod.ls-business-profile.localsearch.cloud',
  auth: {
    ...auth,
    domain: 'preprod.auth.localsearch.cloud',
    audience: 'https://services.lsapis.com.au',
    clientID: 'kwLLZ1U3FZHkrKWJmBqgi7frceGqck1x',
    redirectUri: 'https://preprod.ls-user.localsearch.cloud',
  },
  heapAnalyticsKey: '3826388193',
  deploy: {
    domain: 'preprod.ls-user.localsearch.cloud',
    subDomain: 'preprod.ls-user.localsearch.cloud',
    aliases: 'preprod.ls-user.localsearch.cloud',
    certificateArn:
      'arn:aws:acm:us-east-1:855737094537:certificate/05f63818-355b-4374-bbd5-ca956fafb09c',
  },
};
