import { gql, useQuery } from '@apollo/client';
import { useMemo } from 'react';

const createProfilesQuery = (userId, userRoles) => {
  const roleEdges = userRoles ? userRoles.edges || [] : [];
  const roleProfileIds = roleEdges
    .map(({ node }) => {
      const { roleName = '' } = node || {};
      const [type, id] = `${roleName}`.split('_');
      return type === 'L' ? id || false : false;
    })
    .filter(Boolean);

  const parameters = roleProfileIds.reduce(
    (aggregate, id, i) => `${aggregate}, $profileId${i}: String!`,
    ''
  );
  const roleProfileQueries = roleProfileIds.reduce((aggregate, id, i) => {
    return `${aggregate}
      profile${i}: profile (id: $profileId${i}) {
        id
        displayName
        package {
          name
          id
        }
      }
    `;
  }, '');

  const ProfilesQuery = gql`
    query ProfilesQuery($userId: String${parameters}) {
      profiles(userId: $userId) {
        totalCount
        edges {
          node {
            id
            displayName
            package {
              id
              name
            }
          }
        }
      }
      ${roleProfileQueries}
    }
  `;

  const queryVariables = roleProfileIds.reduce(
    (aggregate, id, i) => ({ ...aggregate, [`profileId${i}`]: id }),
    { userId }
  );

  return {
    ProfilesQuery,
    queryVariables,
    roleProfileIds,
  };
};

const useUserProfiles = contextUser => {
  const { user } = contextUser;
  const { id: userId, userRoles } = user || {};

  const { ProfilesQuery, queryVariables, roleProfileIds } = useMemo(
    () => createProfilesQuery(userId, userRoles),
    [userId, userRoles]
  );

  const { data = {}, loading } = useQuery(ProfilesQuery, {
    variables: queryVariables,
    skip: !userId,
  });

  const allProfiles = useMemo(() => {
    if (loading || !data) {
      return [];
    }
    const { profiles = {} } = data;
    const userProfiles = !profiles.edges
      ? []
      : profiles.edges.map(({ node }) => node);

    const roleProfiles = (roleProfileIds || [])
      .map((id, i) => data[`profile${i}`])
      .filter(Boolean);

    return [...userProfiles, ...roleProfiles];
  }, [roleProfileIds, data, loading]);

  return allProfiles;
};

export default useUserProfiles;
