import React, { useContext, useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Container from 'ls-common-client/src/components/Container';
import Loader from 'ls-common-client/src/components/Loader';
import { Header } from './common';
import { Home, Password, NotFound, HowToManage } from './pages';
import Invitations, { INVITATION_MUTATION_NAMES } from './pages/Invitations';
import { AppContext } from '../context';

const Layout = () => {
  const {
    user: { user },
    error,
  } = useContext(AppContext.Context);

  const { account: { isPasswordUpdatable } = {} } = user || {};

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    if (user || error) {
      setTimeout(() => {
        setLoader(false);
      }, 600);
    }
  }, [user, error]);

  return (
    <>
      <Header />
      {loader && (
        <Container
          backgroundColor="rgba(255,255,255,0.6)"
          position="fixed"
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          transition="all ease 0.5s"
          top="0"
          left="0"
          zIndex="2"
          opacity={user ? 0 : 1}
        >
          <Loader width="200px" />
        </Container>
      )}
      {!!user && (
        <Container
          height="100%"
          maxWidth="1325px"
          paddingTop="70px"
          margin="auto"
        >
          <Routes>
            <Route path="/" element={<Home />} />
            {isPasswordUpdatable && (
              <Route path="/password" element={<Password />} />
            )}
            <Route path="/how-to-manage" element={<HowToManage />} />
            <Route
              path="/invitations/:token"
              element={
                <Invitations
                  invitationMutationName={INVITATION_MUTATION_NAMES.default}
                />
              }
            />
            <Route
              path="/profileInvitations/:token"
              element={
                <Invitations
                  invitationMutationName={INVITATION_MUTATION_NAMES.profile}
                />
              }
            />
            <Route
              path="/awsAccountInvitations/:token"
              element={
                <Invitations
                  invitationMutationName={INVITATION_MUTATION_NAMES.awsAccount}
                />
              }
            />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
      )}
    </>
  );
};

export default Layout;
