import React, {
  useEffect,
  useContext,
  useMemo,
  useCallback,
  useState,
} from 'react';
import Container from 'ls-common-client/src/components/Container';
import Button from 'ls-common-client/src/components/Button';
import Anchor from 'ls-common-client/src/components/Anchor';
import Dialog from 'ls-common-client/src/components/Dialog';
import { Panel, H2, BackBar, Paragraph } from '../common';
import { Face, Wave } from '../../svgs';
import { auth } from '../../services';
import { AppContext } from '../../context';
import CloseButton from '../common/CloseButton';

const HowToManage = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const {
    media: { mobile } = {},
    user: {
      user: {
        id,
        accountId,
        account: { email },
      },
    },
    userProfiles,
  } = useContext(AppContext.Context);

  const emailHref = useMemo(() => {
    const profilesText = userProfiles.reduce(
      (txt, { id: profileId, displayName }) =>
        `${txt}\n\t${profileId}\t ${displayName}`,
      ''
    );
    const subject = encodeURIComponent('Request to delete Localsearch user');
    const body = encodeURIComponent(`
      Please delete my Localsearch user.

      id: ${id}
      account id: ${accountId}
      email: ${email}

      Profile${userProfiles.length > 1 ? 's' : ''}: ${profilesText || 'None'}
    `);
    return `mailto:products@localsearch.com.au?subject=${subject}&body=${body}`;
  }, [id, accountId, email, userProfiles]);

  const onDeleteClick = useCallback(
    confirmed => {
      const paidPackage = userProfiles.some(
        ({ package: pricePackage }) => !!pricePackage
      );
      if (!paidPackage || confirmed) {
        window.location.href = emailHref;
      } else {
        setShowConfirm(true);
      }
    },
    [userProfiles, emailHref]
  );

  const onConfirmed = useCallback(() => {
    onDeleteClick(true);
    setTimeout(() => setShowConfirm(false), 300);
  }, [onDeleteClick]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BackBar />
      <Container display="flex" padding="0 10px" flexWrap="wrap">
        <Panel flex="1" minWidth="300px">
          <Container marginBottom="15px">
            <Face />
          </Container>
          <H2>What happens if you delete your account?</H2>
          <Paragraph marginBottom="20px">
            When you delete your user profile, you also delete all your
            activity, including access to the Business profile manager and
            reviews you&rsquo;ve posted on{' '}
            <Anchor
              href="https://www.localsearch.com.au/"
              target="_blank"
              rel="noreferrer"
            >
              localsearch.com.au
            </Anchor>
            . If you&rsquo;d like to pause your user-exclusive features, you can
            always log out.
          </Paragraph>
          <Paragraph>
            If you would like to discuss your subscription please contact head
            office on <Anchor href="tel:1300360867">1300 360 867</Anchor>.
          </Paragraph>
          <Button
            width="auto"
            padding="0 25px"
            onClick={() => onDeleteClick(false)}
            rounded
            border
          >
            Lodge request
          </Button>
        </Panel>
        <Panel flex="1" minWidth="300px">
          <Container marginBottom="15px">
            <Wave />
          </Container>
          <H2>What happens when I log out of my account?</H2>
          <Paragraph marginBottom="20px">
            By logging out of your{' '}
            <Anchor target="_blank" href="https://www.localsearch.com.au">
              localsearch.com.au
            </Anchor>{' '}
            account, you&apos;ll be able to pause notifications from the app.
            You will need to log in to your account again to update reviews
            you&apos;ve posted, auto-fill forms and more.
          </Paragraph>
          <Button
            width="auto"
            padding="0 25px"
            onClick={auth.logout}
            rounded
            border
          >
            Log out of my account
          </Button>
        </Panel>
      </Container>
      <Dialog
        mode={mobile ? 'mobile' : 'desktop'}
        padding={mobile ? '30px' : '35px 35px 25px 35px'}
        display="flex"
        render={({ children }) => (
          <Container flex="1" position="relative">
            {children}
          </Container>
        )}
        clickOutside
        onClose={() => setShowConfirm(false)}
        show={showConfirm}
      >
        <CloseButton
          onClick={() => setShowConfirm(false)}
          position="absolute"
          right="-20px"
          top="-20px"
        />
        <H2 marginBottom="12px">Are you sure?</H2>
        <Paragraph marginBottom="20px">
          Deleting your user profile will delete all activity and remove access
          to the Business Profile Manager. If you wish to discuss your
          subscription instead, please contact head office on{' '}
          <Anchor href="tel:1300360867">1300 360 867</Anchor>.
        </Paragraph>
        <Button
          primary
          width="auto"
          padding="0 25px"
          onClick={onConfirmed}
          rounded
          margin="0 auto"
        >
          Continue
        </Button>
      </Dialog>
    </>
  );
};

export default HowToManage;
