const defaults = require('./defaults');

const { port, auth } = defaults;

module.exports = {
  ...defaults,
  favicon: true,
  token: {
    ...defaults.token,
    cookie: {
      domain: 'localhost',
    },
  },
  auth: {
    ...auth,
    domain: 'staging.auth.localsearch.cloud',
    audience: 'https://services.lsapis.com.au',
    clientID: '3Z4SeIOgovIcFknBAp9XLNnWs3IaZO6G',
    redirectUri: `http://localhost:${port}`,
  },
  api: 'https://development.services.lsapis.com.au',
};
