const defaults = require('./defaults');

const { auth } = defaults;

module.exports = {
  ...defaults,
  hot: false,
  auth: {
    ...auth,
    domain: 'ls-dashboard-staging.au.auth0.com',
    audience: 'https://performance.lsapis.com.au',
    clientID: 'obF4ofPEQ9OOjLwnajb9EGY1DcRxSvrB',
    redirectUri: 'https://demo.ls-user.localsearch.cloud',
  },
  api: 'https://demo.performance.lsapis.com.au/graphql',
  deploy: {
    domain: 'ls-user.localsearch.cloud',
    subDomain: 'demo.ls-user.localsearch.cloud',
    aliases: 'demo.ls-user.localsearch.cloud',
    certificateArn:
      'arn:aws:acm:us-east-1:789417954668:certificate/a6cc531c-4a68-4bb6-9fb5-7e232081cfa5',
  },
};
