import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import useMedia from 'ls-common-client/src/hooks/useMedia';
import AlertDialog from 'ls-common-client/src/components/AlertDialog';
import { useUser, useUserProfiles } from '../hooks';

const Context = createContext();

const Provider = ({ children }) => {
  const [error, setError] = useState();

  const handleError = (e, throwError = true) => {
    setError(e);
    if (throwError) {
      throw e;
    }
  };

  const theme = useContext(ThemeContext);
  const media = useMedia(theme.mediaQueries);
  const user = useUser();
  const userProfiles = useUserProfiles(user);

  return (
    <Context.Provider
      value={{
        user,
        userProfiles,
        media,
        handleError,
        error,
      }}
    >
      {children}
      <AlertDialog
        show={!!error}
        heading="Oops, something went wrong."
        text={error ? error.message : ''}
        buttons={[
          {
            children: 'OK',
            type: 'button',
            props: {
              primary: true,
              rounded: true,
              onClick: () => setError(false),
            },
          },
        ]}
      />
    </Context.Provider>
  );
};

Provider.propTypes = {
  children: PropTypes.node,
};

Provider.defaultProps = {
  children: null,
};

export { Provider, Context };
